<template>
	<div style='min-width: 1450rem'>
		<el-form ref='ruleForm' :model='ruleForm' class='demo-ruleForm form-body' label-width='140rem' style="padding-top: 70rem;padding-left: 48rem">
			<el-form-item label='续费学年'>{{ ruleForm.year }}</el-form-item>
			<el-form-item label='应用校区'>{{ ruleForm.school_name }}</el-form-item>
			<el-form-item label='应用年级'>{{ ruleForm.grade_name }}</el-form-item>
			<el-form-item label='开始续费时间'>{{ ruleForm.start_pay_time }}</el-form-item>
			<el-form-item label='结束续费时间'>{{ ruleForm.end_pay_time }}</el-form-item>
			<!--			<el-form-item label='购买商品套餐'>{{ ruleForm.goods_pack_name }}</el-form-item>-->
			<!--			<el-form-item label='购买商品规格'>{{ ruleForm.goods_standard_name }}</el-form-item>-->
			<el-form-item label='备注'>
				<div style="width: 374rem;word-wrap: anywhere;">{{ ruleForm.remark }}</div>
			</el-form-item>
		</el-form>
		<div class="footer-button">
			<el-button @click='$router.back()'>返回</el-button>
		</div>
	</div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';

export default {
	_config:{"route":{"path":"look","meta":{"title":"查看"}}},
  data() {
		// 这里存放数据
		return {
			ruleForm: {},
		};
	},
	created() {
		// 获取详情
		this.$_register.get('api/recruit/continue-pay/show', {params: {id: this.$route.query.id}}).then(res => {
			this.ruleForm = res.data.data
		})
	},
};
</script>

<style scoped>
</style>
